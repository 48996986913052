import React, { useRef, useState } from 'react'
import "./Header.css";
import { Link, useNavigate } from 'react-router-dom';
import { getMobiles } from '../features/firebase/mobilesDB';

const Header = () => {
    const inputRef = useRef(null);
    const navigate = useNavigate()
    
    const [mobiles,setMobiles]=useState(null)
    
    const getMobilesData = async() => {
      const res = await getMobiles();
      setMobiles(res)
    }
  
    const submitHandler = () => {
      navigate(`/${inputRef.current.value}`)
    }
  return (
    <div style={{marginTop:"20px",marginBottom:"20px"}}>
    <div class="offcanvas offcanvas-start w-10" tabIndex="-1" id="offcanvas"  >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title d-none d-sm-block" id="offcanvas">Menu</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body px-0">
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start" id="menu">
            <li class="nav-item">
              <a href="/home" class="nav-link text-truncate">
                <i class="fa-solid fa-house"></i>  <span class="ms-1 d-none d-sm-inline">Home</span>
              </a>
            </li>

            <li class="dropdown">
              <a href="#" class="nav-link dropdown-toggle  text-truncate" id="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-language"></i>  <span class="ms-1 d-none d-sm-inline">Language</span>
              </a>
              <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdown">
                <li><a class="dropdown-item" href="#">English</a></li>
                <li><a class="dropdown-item" href="#">French</a></li>
                <li><a class="dropdown-item" href="#">Spanish</a></li>
                <li><a class="dropdown-item" href="#">Chinese</a></li>

              </ul>
            </li>

            <li>
              <a href="/contact" class="nav-link text-truncate">
                <i class="fa-solid fa-phone"></i>  <span class="ms-1 d-none d-sm-inline">Contact Us</span></a>
            </li>
            <li>
              <a href="/rate" class="nav-link text-truncate">
                <i class="fa-solid fa-star"></i>  <span class="ms-1 d-none d-sm-inline">Rate our App</span> </a>
            </li>
            <li>
              <a href="/about" class="nav-link text-truncate">
                <i class="fa-solid fa-circle-info"></i>  <span class="ms-1 d-none d-sm-inline">About</span> </a>
            </li>
            <li>
              <a href="/coupons" class="nav-link text-truncate">
                <i class="fa-solid fa-gift"></i>  <span class="ms-1 d-none d-sm-inline">My Coupons</span> </a>
            </li>
            <li>
              <a href="/login" class="nav-link text-truncate">
                <i class="fa-solid fa-right-from-bracket"></i>  <span class="ms-1 d-none d-sm-inline">Log out</span> </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-1 logo float-end" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" typeof='button' aria-label="Close">

            <i className="fa-solid fa-bars fa-xl" />

          </div>
          <div className="col-1 logo">
            <img className="homepage-logo" src="logo.png" alt="" width="25" height="28" />
          </div>
          <div className="col-1 pricewise">
            <h6 className='pw'>PriceWise</h6>
          </div>
          <div className="col-4">
            <input ref={inputRef}  type="search" id="form1" className="form-control" placeholder="Search" />
          </div>
          <div className="col-1">
            <button onClick={submitHandler} type="button" className="btn btn-primary search-button">
              <i className="fas fa-search"></i>
            </button>
          </div>
          <Link to={'/notifications'} className="col-1">
            <i className="fa-solid fa-bell fa-xl"></i>
          </Link>
          <Link to={'/profile'} className="col-1">
            <i className="fa-solid fa-user fa-xl"></i>
          </Link>
          <Link to={'/recent'} className="col-1">
            <i className="fa-solid fa-clock fa-xl"></i>
          </Link>
        </div>
      </div></div>
  )
}

export default Header